import React, { Component } from 'react';
import { Lightbox } from './lightbox';
import { WindowSizeContext } from '../context/WindowSizeContext';

export class UnsupportedBrowserWarning extends Component {
  state = {
    isIE11: false,
    isSafari: false,
    isIOs: false,
    lightboxVisible: false
  };

  componentDidMount() {
    this.setState({
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      isIE11: !!window.MSInputMethodContext && !!document.documentMode,
      isIOs: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    });
  }

  render() {
    const { isSafari, isIE11, isIOs, lightboxVisible } = this.state;

    if (isIOs) {
      return (
        <WindowSizeContext.Consumer>
          {isMobile => {
            if (!isMobile) return;
            return (
              <div className="featureWarning">
                <strong>Achtung: </strong>
                Auf Ihren Endgerät könnte unsere Karte falsch dargestellt werden!
              </div>
            );
          }}
        </WindowSizeContext.Consumer>
      );
    }
    if (isSafari || isIE11) {
      return (
        <Lightbox
          usePanZoom={false}
          closable={isSafari ? true : false}
          content={
            <div style={{ padding: '20vh 23vw' }}>
              <span
                style={{
                  marginBottom: '30px',
                  display: 'block',
                  borderTop: '40px solid rgb(204,204,204)',
                  padding: '33px 85px',
                  background: '#fff',
                  textAlign: 'center'
                }}
              >
                <strong
                  style={{
                    marginBottom: '32px',
                    display: 'block'
                  }}
                >
                  Es tut uns leid, aber leider unterstützt Ihr Browser die technischen Anforderungen
                  des Beteiligungstools nicht. Bitte verwenden Sie einen der folgenden Browser:
                  <br /> Google Chrome, Mozilla Firefox oder Microsoft Edge
                </strong>
              </span>
            </div>
          }
        >
          {({ openLightbox }) => {
            if (!lightboxVisible) {
              this.setState({ lightboxVisible: true }, () => {
                openLightbox();
              });
            }
          }}
        </Lightbox>
      );
    }

    return null;
  }
}
